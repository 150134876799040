import React, { useRef, useEffect, useState } from "react"
import axios from "axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Layout from "../components/layout"
import SEO from "../components/seo"
import page from "../data/aiForGoodData"
import CoronaImg from "../images/ws/corona.png"
import { faAngleUp } from "@fortawesome/free-solid-svg-icons"
import AnimatedLink from "../components/AnimatedLink"
const AiForGood = () => {
  let overviewRef = useRef()
  let workSafeRef = useRef()
  let trackSafeRef = useRef()
  let valuePropRef = useRef()
  // // let getStartedRef = useRef()
  let navRef = useRef()

  const [fixedNav, setFixedNav] = useState(false)
  const [showScrollTop, setShowScrollTop] = useState(false)
  const [fixedBottom, setFixedBottom] = useState(20)
  const [overviewInView, setOverviewInView] = useState(false)
  const [workSafeInView, setWorkSafeInView] = useState(false)
  const [trackSafeInView, setTrackSafeInView] = useState(false)
  const [valuePropInView, setValuePropInView] = useState(false)
  const [submittingFlag, setSubmitFlag] = useState(false)
  const [workEmail, setWorkEmail] = useState("")
  // const [getStartedInView, setGetStartedInView] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", () => {
      // console.log(workSafeRef.current.classList)
      //console.log(overviewRef.current.getBoundingClientRect())
      if (!overviewRef.current) return
      const ovTop = overviewRef.current.offsetTop
      const wsTop = workSafeRef.current.offsetTop
      const tsTop = trackSafeRef.current.offsetTop
      const vpTop = valuePropRef.current.offsetTop
      // // const gsTop = getStartedRef.current.offsetTop
      // console.log(ovTop >= 0 && ovTop <= window.innerHeight)
      const ovHeight = overviewRef.current.getBoundingClientRect().height
      const wsHeight = workSafeRef.current.getBoundingClientRect().height
      const tsHeight = trackSafeRef.current.getBoundingClientRect().height
      const vpHeight = valuePropRef.current.getBoundingClientRect().height
      // const gsHeight = getStartedRef.current.getBoundingClientRect().height
      const navHeight = navRef.current.getBoundingClientRect().height + 60

      setOverviewInView(
        ovTop - navHeight <= window.pageYOffset &&
          ovTop + ovHeight - navHeight > window.pageYOffset
      )

      setWorkSafeInView(
        wsTop - navHeight <= window.pageYOffset &&
          wsTop + wsHeight - navHeight > window.pageYOffset
      )

      setTrackSafeInView(
        tsTop - navHeight <= window.pageYOffset &&
          tsTop + tsHeight - navHeight > window.pageYOffset
      )
      console.log(vpTop - 100 - navHeight <= window.pageYOffset)
      console.log(tsTop + tsHeight - navHeight > window.pageYOffset)
      // setValuePropInView(vpTop - 100 - navHeight <= window.pageYOffset)

      setValuePropInView(
        vpTop - navHeight <= window.pageYOffset &&
          vpTop + vpHeight - navHeight > window.pageYOffset
      )
      // setGetStartedInView(
      //   gsTop - navHeight <= window.pageYOffset &&
      //     gsTop + gsHeight - navHeight > window.pageYOffset
      // )

      setFixedNav(window.pageYOffset > 730)
      setShowScrollTop(window.pageYOffset > 100)
      setFixedBottom(
        window.scrollY + window.innerHeight >= document.body.offsetHeight
          ? 70
          : 20
      )
    })
    return () => {
      window.removeEventListener("scroll", () => {})
    }
  }, [])
  const scrollToRef = ref => {
    if (ref) window.scrollTo(0, ref.current.offsetTop - 100)
  }

  const SubHeaderDiv = ({ heading, classes = "" }) => (
    <>
      <h2 className={`text-center  d-none d-md-block container ${classes}`}>
        {heading}
      </h2>
      <h5 className={`text-center  d-block d-md-none container ${classes}`}>
        {heading}
      </h5>
    </>
  )
  function downloadURI(uri, name) {
    console.log(uri)
    var a = document.createElement("a")
    a.href = uri
    a.target = "_blank"
    a.setAttribute("download", name)
    a.click()
  }

  const onDownloadBrochure = e => {
    e.preventDefault()
    setSubmitFlag(true)
    axios
      .post(
        "https://vulcanwebcontact.azurewebsites.net/api/download-brochure?code=HDB5EI/5aM6IwOr7w8cYkAMphjfP0hwjX1uufzQZ8tRYwdLIxbrMNw==",
        { workEmail }
      )
      .then(res => {
        console.log(res)
        if (res.data && res.data.links) {
          res.data.links.forEach(x => window.open(x.url))
        }
        // setShowSuccess(true)
        // setShowError(false)
        // setSubmitMessage(res.data)
        setSubmitFlag(false)
      })
      .catch(err => {
        // setShowError(true)
        // setShowSuccess(false)
        // setSubmitMessage(err.data)
        setSubmitFlag(false)
      })
  }
  return (
    <Layout pageInfo={{ pageName: "ai-for-good" }} hideTimeline={true}>
      <SEO title={page.name} description={page.titleTxtProposition} />
      <div className="bg-white ai-for-good-line-height ">
        <div id="ai-for-good-header" className="bg-light">
          <div className="content d-none d-md-flex">
            <div className="left">
              <h1 className="title">{page.name}</h1>
              <div className="title-desc">{page.titleTxt}</div>
              {/* 
              <button
                onClick={() => scrollToRef(overviewRef)}
                className="bg-light mt-5 btn-new"
                style={{ width: 135 }}
              >
                Learn More
              </button> */}
            </div>
            <div className="right">
              <img src={CoronaImg} alt="Corona" style={{ width: "468px" }} />
            </div>
          </div>
          <div className="content-sm d-flex d-md-none pb-5">
            <div className="top">
              <img
                src={CoronaImg}
                alt="Corona"
                style={{ width: "100%", height: "100%" }}
                className="m-auto"
              />
            </div>

            <div className="bottom">
              <div className="container">
                <h1 className="title">{page.name}</h1>
                <div className="title-desc">{page.titleTxt}</div>
              </div>
            </div>
          </div>
        </div>
        <section id="ai-for-good-main" className="pt-5 mb-5">
          <h2 className="text-center mt-5">{page.solutionsTitle}</h2>
          <div className={` ${fixedNav ? "is-fixed" : "d-none"}`} ref={navRef}>
            <div className={`nav-bar d-none d-md-flex`}>
              <div
                className={`nav-item cursor-pointer ${
                  overviewInView ? "text-bold" : ""
                }`}
                onClick={() => scrollToRef(overviewRef)}
              >
                Overview
              </div>
              <div
                className={`nav-item  cursor-pointer ${
                  workSafeInView && !overviewInView ? "text-bold" : ""
                }`}
                onClick={() => scrollToRef(workSafeRef)}
              >
                WorkSafe
              </div>
              <div
                className={`nav-item  cursor-pointer ${
                  trackSafeInView && !workSafeInView && !valuePropInView
                    ? "text-bold"
                    : ""
                }`}
                onClick={() => scrollToRef(trackSafeRef)}
              >
                TrackSafe
              </div>
              <div
                className={`nav-item  cursor-pointer ${
                  valuePropInView ? "text-bold" : ""
                }`}
                onClick={() => scrollToRef(valuePropRef)}
              >
                Value Proposition
              </div>
              {/* <div
                className={`nav-item  cursor-pointer ${
                  getStartedInView && !valuePropInView ? "text-bold" : ""
                }`}
                onClick={() => scrollToRef(getStartedRef)}
              >
                Getting Started
              </div> */}
            </div>
          </div>
        </section>
        <section
          className="container pb-5 "
          id="ai-for-good-solutions"
          ref={overviewRef}
        >
          <div className="row text-center">
            {page.soultions.map(solution => (
              <div className="col-md-6 mt-5 text-center" key={solution.title}>
                {/* <img
                src={solution.img}
                alt={solution.title}
                style={{
                  border: "4px solid #595959",
                  height: "250px",
                  width: "250px",
                  borderRadius: "50%",
                  padding: "3.5rem",
                }}
              /> */}
                <div
                  style={{
                    height: "200px",
                    width: "200px",
                    borderRadius: "50%",
                    margin: "auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className={`mb-4 ${solution.title.toLowerCase()}`}
                >
                  <h2
                    style={{
                      fontSize: "2rem",
                    }}
                    className="text-bold"
                  >
                    {solution.title}
                  </h2>
                </div>
                <div
                  className="features pt-4"
                  style={{
                    margin: "auto",
                    textAlign: "left",
                  }}
                >
                  {solution.list_desc.map((desc, i) => (
                    <div style={{ marginBottom: "1rem" }} key={i}>
                      {desc}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </section>
        <section
          id="ai-for-good-worksafe"
          className="active bg-gray-ai-for-good my-5 py-5"
          ref={workSafeRef}
        >
          <div className="pt-5">
            <h2
              style={{ fontSize: "35px" }}
              className="mb-4 text-center text-worksafe"
            >
              {page.soultions[0].title}
            </h2>
            <div
              className=" container description text-center"
              style={{ margin: "auto" }}
            >
              {page.soultions[0].key_functionalities.map((x, i) => (
                <div
                  style={{
                    marginBottom: "0.5rem",
                    fontSize: "1.25rem",
                    lineHeight: "2rem",
                    textAlign: "left",
                  }}
                  key={i}
                >
                  {x}
                </div>
              ))}
            </div>
            <div id="apprising" className="apprising  pt-5 ">
              <SubHeaderDiv
                heading={page.soultions[0].key_functions_heading}
                classes="text-worksafe "
              ></SubHeaderDiv>
              <div className="container mt-4 mb-3">
                <span>{page.soultions[0].key_functions_heading_desc}</span>
              </div>
              <div className="container">
                <div className=" functions row">
                  {page.soultions[0].functionalities.map((x, i) => (
                    <div className={`function col-md-6 my-4`} key={i}>
                      <img
                        src={x.img}
                        alt={x.title}
                        style={{
                          height: "250px",
                          width: "100%",

                          background: "#fff",
                          objectFit: x.img_fit,
                        }}
                        className={`d-none d-lg-block `}
                      />
                      <img
                        src={x.img}
                        alt={x.title}
                        style={{
                          height: "200px",
                          width: "100%",

                          background: "#fff",
                          objectFit: x.img_fit,
                        }}
                        className="d-block d-lg-none"
                      />
                      <h5 className="mt-4 mb-2 d-none d-lg-block">{x.title}</h5>
                      <h5
                        className="mt-4 mb-2 d-block d-lg-none"
                        style={{ fontSize: "18px" }}
                      >
                        {x.title}
                      </h5>

                      <div className="line-height-1_5">{x.desc}</div>
                      {x.desc_2 ? (
                        <div className="pt-3 line-height-1_5">{x.desc_2}</div>
                      ) : null}
                      {x.desc_3 ? (
                        <div className="pt-3 ">{x.desc_3}</div>
                      ) : null}
                    </div>
                  ))}
                </div>
              </div>{" "}
            </div>
          </div>
          <div id="how-worksafe-works" className="how-it-works my-5">
            <SubHeaderDiv
              heading="How Does it Work"
              classes="mb-4 text-worksafe"
            ></SubHeaderDiv>

            <div className="container ">
              <div className="steps row ">
                {page.soultions[0].steps.map((step, i) => (
                  <div className="step col-md-6 col-lg-3 my-4" key={i}>
                    <div className="icons pb-3">
                      <div className="circle worksafe">
                        <div className="name">{step.title}</div>
                      </div>
                    </div>
                    <div className="text mt-3 d-none d-md-block line-height-1_5">
                      {step.desc}
                    </div>
                    <div className="text-center mt-3 d-block d-md-none line-height-1_5">
                      {step.desc}
                    </div>

                    <div className="text  pt-3 d-none d-md-block line-height-1_5">
                      {step.desc_2}
                    </div>
                    <div className="text-center pt-3 d-block d-md-none line-height-1_5">
                      {step.desc_2}
                    </div>

                    {step.bullets ? (
                      <ul>
                        {step.bullets.map((x, i) => (
                          <li key={i}>{x}</li>
                        ))}{" "}
                      </ul>
                    ) : null}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        <section id="ai-for-good-tracksafe" className="py-5" ref={trackSafeRef}>
          <h2
            style={{ fontSize: "35px" }}
            className="mb-4 text-center text-tracksafe"
          >
            {page.soultions[1].title}
          </h2>
          <div
            className="description text-center container"
            style={{ margin: "auto" }}
          >
            {page.soultions[1].key_functionalities.map((x, i) => (
              <div
                style={{
                  marginBottom: "0.5rem",
                  fontSize: "1.25rem",
                  lineHeight: "2rem",
                  textAlign: "left",
                }}
                key={i}
              >
                {x}
              </div>
            ))}
          </div>
          <div id="apprising" className="apprising pt-5">
            <SubHeaderDiv
              heading={page.soultions[1].key_functions_heading}
              classes="text-tracksafe "
            ></SubHeaderDiv>
            <div className="container  mt-4 mb-3">
              <span>{page.soultions[1].key_functions_heading_desc}</span>
            </div>
            <div className="container">
              <div className=" functions row">
                {page.soultions[1].functionalities.map((x, i) => (
                  <div className="function col-md-6 my-4" key={i}>
                    <img
                      src={x.img}
                      alt={x.title}
                      style={{
                        height: "225px",
                        width: "100%",
                        objectFit: x.img_fit,
                        // boxShadow: "0px 3px 6px #00000029",
                      }}
                      className="bg-white d-none d-lg-block"
                    />
                    <img
                      src={x.img}
                      alt={x.title}
                      style={{
                        height: "200px",
                        width: "100%",
                        objectFit: x.img_fit,
                      }}
                      className="bg-white d-block d-lg-none"
                    />
                    <h5
                      style={{ fontSize: "18px" }}
                      className="mt-4 mb-2  d-none d-md-block d-lg-none"
                    >
                      {x.title}
                    </h5>
                    <h5 className="mt-4 mb-2  d-none  d-lg-block">{x.title}</h5>
                    <h5
                      className="mt-4 mb-2  d-block d-md-none"
                      style={{ fontSize: "18px" }}
                    >
                      {x.title}
                    </h5>
                    <div className="d-none d-md-block w-100 line-height-1_5">
                      {x.desc}
                    </div>
                    <div className="pb-4 d-block d-md-none w-100 line-height-1_5">
                      {x.desc}
                    </div>
                  </div>
                ))}
              </div>
            </div>{" "}
          </div>
          <div id="how-tracksafe-works" className="how-it-works py-5">
            <SubHeaderDiv
              heading="How Does it Work"
              classes="text-tracksafe my-4"
            ></SubHeaderDiv>
            <div className="container ">
              <div className="steps row px-0">
                {page.soultions[1].steps.map((step, i) => (
                  <div className="step col-md-4 col-lg-2 my-4" key={i}>
                    <div className="icons pb-3">
                      <div className={`circle tracksafe ${step.textColor}`}>
                        <div className="name">{step.title}</div>
                      </div>
                    </div>
                    <div className="text mt-3 d-none d-md-block line-height-1_5">
                      {step.desc}
                    </div>
                    <div className="text-center mt-3 d-block d-md-none line-height-1_5">
                      {step.desc}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        <section
          ref={valuePropRef}
          id="ai-for-good-value-prop"
          className="how-it-works py-5 bg-gray-ai-for-good"
        >
          <h2 style={{ fontSize: "35px" }} className="my-5 text-center">
            Value Proposition
          </h2>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h3 className="text-center d-none d-md-block mb-4">Features</h3>
                <h5 className="text-center d-block d-md-none mb-4">Features</h5>
                <ul>
                  {page.features.map((x, i) => (
                    <li key={i} className="my-3 line-height-1_5">
                      {x}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-md-6">
                <h3 className="text-center d-none d-md-block mb-4">Benefits</h3>
                <h5 className="text-center d-block d-md-none mb-4">Benefits</h5>
                <ul>
                  {page.benefits.map((x, i) => (
                    <li key={i} className="my-3 line-height-1_5">
                      {x}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="text-center mt-5">
              <div className="form">
                <form
                  action=""
                  className="form-group mt-4"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                  onSubmit={onDownloadBrochure}
                >
                  <input
                    type="email"
                    placeholder="Work Email"
                    style={{ width: "300px" }}
                    className="mx-4 "
                    value={workEmail}
                    onChange={e => setWorkEmail(e.target.value)}
                    required
                  />
                  <input
                    disabled={submittingFlag}
                    type="submit"
                    style={{ width: "200px" }}
                    value=" Download Brochure"
                    className="btn btn-outline-dark text-bold d-none d-md-block"
                  />
                  <input
                    disabled={submittingFlag}
                    type="submit"
                    style={{ width: "200px" }}
                    value=" Download Brochure"
                    className="btn btn-outline-dark text-bold d-block d-md-none mt-2"
                  />
                  {/* <button className="bg-light btn-new mt-2">
                    Download Brochure
                  </button> */}
                </form>
              </div>
              <div className="text-center  mb-5">
                <AnimatedLink
                  to="/contact#contact-form"
                  className="link-no-style"
                >
                  <button
                    onClick={() => scrollToRef(overviewRef)}
                    className="btn btn-outline-dark text-bold  mt-3"
                  >
                    Contact Us
                  </button>
                </AnimatedLink>
              </div>
            </div>
          </div>
        </section>
        {/* <section
          className="my-5"
          id="ai-for-good-get-started"
          ref={getStartedRef}
        >
          <SubHeaderDiv heading="Get Started" classes=""></SubHeaderDiv>
          <div className="container ">
            <div className="text-center">
              If you have existing Cameras, for most clients the system can be
              set up in 1-2 weeks. 
            </div>
            <div className="mb-4 text-center">
              Large scale implementations and custom requirements may take
              longer
            </div>
            <div className="steps row ">
              {page.get_started_steps.map((step, i) => (
                <div className="step col-md-4 col-lg-2 my-3" key={i}>
                  <div className="icons">
                    <div className="circle">
                      <div className="name">{i + 1}</div>
                    </div>
                  </div>
                  <div className="text mt-2 d-none d-lg-block">{step}</div>
                  <div className="text-center mt-2 d-block d-lg-none">
                    {step}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="text-center mt-4">
            <div className="form">
              <div className="form-group mt-4 ">
                <input
                  type="email"
                  placeholder="Work Email"
                  className="mx-4 mt-2"
                />
                <button className="bg-light btn-new mt-2">
                  Download Brochure
                </button>
              </div>
            </div>
          </div>
          <div className="text-center">
            <button
              onClick={() => scrollToRef(overviewRef)}
              className="bg-light mt-4 btn-new"
            >
              Contact Us
            </button>
          </div>

          <div className="py-4"></div>
        </section> */}
        {/* <a href="https://www.freepik.com/free-photos-vectors/danger">
          Danger vector created by starline - www.freepik.com
        </a> */}
        {showScrollTop ? (
          <button
            className="btn-scroll-top"
            style={{ bottom: fixedBottom }}
            onClick={() => window.scrollTo(0, 0)}
          >
            <FontAwesomeIcon icon={faAngleUp} size={"2x"}></FontAwesomeIcon>
          </button>
        ) : null}
      </div>
    </Layout>
  )
}
export default AiForGood
