import WorkSafeImg from "../images/ws/worksafe.png"
import TrackSafeImg from "../images/ws/tracksafe.png"
import LockImg from "../images/ws/lock.png"
import BrainCogImg from "../images/ws/braincog.png"
import BrainImg from "../images/ws/globe.png"
import ChipImg from "../images/ws/chip.png"
import ChartImg from "../images/ws/chart.png"
import LibraImg from "../images/ws/compliant.png"
import PeopleDetectionImg from "../images/ws/people_detection.png"
import VisualCuesImg from "../images/ws/visual_cues.png"
import RiskManagementImg from "../images/ws/risk_management.png"
import OperationsConsoleImg from "../images/ws/risk_report.png"
import TsChartImg from "../images/ws/ts-chart.png"
import TsXImg from "../images/ws/ts-x.png"
import CloseContactImg from "../images/ws/close_contact.png"
import AreasVisitedImg from "../images/ws/areas_visited.png"
import FaceMaskIdImg from "../images/ws/anonymized_avatar.png"
import GraphImg from "../images/ws/graph.png"
export default {
  name: "Using AI for Good",
  titleTxt:
    "Check out our new AI solutions to prevent and control the spread of Covid-19 at your workplace",
  solutionsTitle: "Vulcan AI's Covid-19 Solutions",
  soultions: [
    {
      title: "WorkSafe",
      img: WorkSafeImg,
      list_desc: [
        "AI powered Video Solution to prevent spread of Covid-19 at the workplace.",
        "Real time monitoring and actionable alerts to encourage and ensure safe practices at work.",
        "Allows businesses to prevent spread of Covid-19 and stay open for business.",
        "Applies to Offices, Factories, Warehouses, Ports, Hospitals, Hotels, Retail Stores, Restaurants etc.",
      ],
      key_functionalities: [
        "WorkSafe is an Artificial Intelligence Application that gives you timely and actionable alerts to help keep your employees and partners safe from Covid-19. WorkSafe helps you monitor and drive behavioral change to manage Covid-19 risk.",
      ],
      key_functions_heading: "AI Powered Behavioural Change & Risk Management",
      key_functions_heading_desc:
        "Humans are creatures of habit, and may need constant reminders and feedback to get used to the “new normal”. Always forgetting to wear your mask after a cup of coffee? WorkSafe provides an automated and near real-time way to nudge your staff and remind them to follow the new protocols.",
      features: [
        {
          icon: LockImg,
          title: "Secure",
          bgColor: "#57B6C0",
          desc:
            "Runs in your existing IT environment, thus no data leaves your secure environment.",
        },
        {
          icon: BrainCogImg,
          title: "Deep Learning",
          bgColor: "#74BDA7",

          desc:
            "Uses Deep Learning AI models to detect and alert you to behaviors that pose a significant risk to your continued business operations.",
        },
        {
          icon: ChartImg,
          title: "Gentle nudges",
          bgColor: "#7A8B8E",
          desc:
            "Drives behavioural change with active but gentle “nudges” to do the right thing.",
        },
        {
          icon: ChipImg,
          title: "Configurable",
          bgColor: "#2583C6",
          desc:
            "Role based access that can be set up to align with your existing data access and security protocols.",
        },
        {
          icon: BrainImg,
          bgColor: "#84ABB6",
          title: "Decentralized risk management",
          desc:
            "Allows you to monitor and manage risk across multiple facilities, anywhere in the world, through a centralized dashboard.",
        },

        {
          icon: LibraImg,
          bgColor: "#57B6C0",
          title: "Privacy compliant",
          desc:
            "Compliant with Personal Data Privacy and Protection regulations.",
        },
      ],
      functionalities: [
        {
          title: "Configurable Thresholds",
          img: PeopleDetectionImg,
          img_fit: "cover",
          desc:
            "WorkSafe detects anyone not wearing a mask or not keeping safe distance, or the occupancy goes above the set threshold, a reminder message can be displayed on a nearby screen.",
          // desc:
          //   "The people detection, safe distance and density alerts can be configured according to safe distance regulations, max number of people allowed in an office.",
        },
        {
          title: "Visual Cues & Nudges",
          img: VisualCuesImg,
          img_fit: "contain",
          desc:
            "WorkSafe facilitates visual cues and alerts through displays to remind and encourage the right behaviour in the “new normal”.",

          desc_2:
            "Nudges can be sent to occupiers through emails or mobile notifications to encourage the right behaviour and following of protocols, allowing near real-time behaviour correction.",
        },
        {
          title: "Risk Management Ops Console",
          img: RiskManagementImg,
          img_fit: "scale-down",
          desc:
            "Facility Managers and SMOs get real time visibility of Covid-19 risk hot spots in their facilities through a web or mobile app. It will show them exactly where they need to take corrective action. ",
          desc_2:
            "Precise location insights facilitate effective deployment of SMOs, reducing manpower needs to physically manage crowds, monitor and correct high risk behavior. ",
          desc_3:
            "Alerts and insights for each location are available through operation consoles that can be accessed on the web from anywhere.",
        },
        {
          title: "Daily Risk Reports",
          img: OperationsConsoleImg,
          img_fit: "scale-down",
          desc:
            "Management will receive daily risk assessment reports which highlight high-risk workspaces, behaviours and individuals that may be putting the workplace at risk of Covid-19. ",
          desc_2:
            "Identify potential risks to staff and partners and recommend remedial action to prevent the spread of Covid-19 and avoid business disruption. ",
        },
      ],
      steps: [
        {
          title: "1",

          desc:
            "We install WorkSafe application in your premises and onboard existing cameras. Data does not leave your premises/data center and is thus secure",
        },
        {
          title: "2",

          desc:
            "Based on your requirements we configure various alerts and reminders that need to be sent through various channels",
        },
        {
          title: "3",

          desc:
            "We test the system for a week and refine the alerts if needed before we go live.",
          desc_2: "Start receiving reminders and daily reports",
        },
        {
          title: "4",

          desc: "Start receiving:",
          bullets: ["Nudges", "Daily Reports", "Ops Console"],
        },
      ],
    },
    {
      title: "TrackSafe",
      img: TrackSafeImg,
      list_desc: [
        "AI powered Video Analytics solution for controlling and managing the spread of Covid-19 through fast and automated contact tracing.",
        "On-Demand solution which is activated only once confirmed Covid-19 Case is detected.",
        "Privacy is preserved for people who did not come in contact with Covid-19 infected person.",
        "Allows you to quickly identify “close” contacts that need to be isolated for testing, and lets you resume business operation sooner.",
      ],
      key_functionalities: [
        "TrackSafe is an Artificial Intelligence Application that automates contact tracing to instantly identify people that need to be isolated for testing and lets you resume the rest of your operations sooner.",
        "Your insurance against business disruption when a Covid-19 case happens at your premises.",
      ],
      key_functions_heading:
        "Reduce manual investigation, resources and time by more than 90%",
      key_functions_heading_desc:
        "Tracksafe offers an automated way that taps on existing CCTV cameras. Instead of taking days of manual labour to go through all the videos in the 25 days incubation period, you can narrow it down to the 5 people who were in close and prolonged exposure with the infected party in 30 minutes and identify the exact locations where the infected had spent extended amount of time. This facilitates targeted area closures, thorough disinfection and prevents the shutdown of the whole office, allowing businesses to resume sooner.",
      features: [
        {
          title: "Secure",
          icon: LockImg,
          bgColor: "#57B6C0",
          desc:
            "Runs in your existing IT environment, thus no data leaves your secure environment.",
        },
        {
          title: "Deep Learning",
          icon: BrainCogImg,
          bgColor: "#74BDA7",
          desc:
            "Uses Deep Learning AI models to detect and alert you to behaviours that pose a significant risk to your continued business operations.",
        },
        {
          title: "Quick Actions",
          icon: TsXImg,
          bgColor: "#7A8B8E",
          desc:
            "Allows you to quickly isolate people and operations that have been exposed to take necessary actions.",
        },
        {
          title: "Configurable",
          icon: ChipImg,
          bgColor: "#84ABB6",
          desc:
            "Role based access that can be set up to align with your existing data access and security protocols.",
        },
        {
          title: "Compliant",
          icon: LibraImg,
          bgColor: "#2583C6",
          desc:
            "Compliant with Personal Data Privacy and Protection regulations.",
        },
        {
          title: "Restart Business",
          icon: TsChartImg,
          bgColor: "#57B6C0",
          desc: "Helps you restart business operations sooner.",
        },
      ],
      when_case_identified: [
        "Imagine you are responsible for a facility that has a few hundred staff and visitors coming in every day. When you get a Covid-19 case you will need to identify all the close contacts in your facilities so that they can be immediately quarantined and sent for testing.",
        "If you do not have an automated contact tracing system you may have to shut down the full facilities and send everyone for testing.",
        "Alternatively, if you have CCTV cameras, you could manually trace the contacts of the Covid-19 patient. This may mean going through days and days of video recordings before you can find the close contacts, thus losing precious time in isolating the close contacts and stopping further infections.",
        "TrackSafe helps eliminate the need for manual search and contact tracing activities through AI. Within 30 minutes, you can get all the degrees of contact as well as areas visited that you need to close off for further disinfection.",
      ],
      functionalities: [
        {
          title: "Identify Close Contacts",
          img: CloseContactImg,
          img_fit: "cover",
          desc:
            "AI to trace individuals that came in contact with confirmed/potentially infected.",
        },
        {
          title: "Anonymized Avatars",
          img: FaceMaskIdImg,
          img_fit: "scale-down",
          desc:
            "Use of anonymized avatars that do not use facial recognition thus preserving privacy.",
        },
        {
          title: "Identify Areas Visited",
          img: AreasVisitedImg,
          img_fit: "scale-down",
          desc:
            "Ability to identify areas that were visited by the Person of Interest and close them for disinfection.",
        },
        {
          title: "Configurable Contact Tracing",
          img: GraphImg,
          img_fit: "cover",
          desc:
            "“Close Contacts” can be configured based on number and length of exposure to another person.",
        },
      ],
      steps: [
        {
          title: "1",

          textColor: "text-dark",
          desc:
            "You are informed of a staff member whose results have just come out positive for Covid-19.",
        },
        {
          title: "2",

          textColor: "text-dark",
          desc:
            "You log on to TrackSafe and upload some pictures of the infected staff member to search for close contacts.",
        },
        {
          title: "3",

          textColor: "text-dark",
          desc:
            "You upload/select the video feeds for the last 25 days for the duration the staff was on the premises.",
        },
        {
          title: "4",

          textColor: "text-white",
          desc:
            "The AI application processes the feeds and only returns the images of people who came in direct or indirect contact.",
        },
        {
          title: "5",

          textColor: "text-white",
          desc:
            "You identify the people in the contact search results and isolate them for testing.",
        },
        {
          title: "6",

          textColor: "text-white",
          desc:
            "The rest of the business continues operation as usual, thus minimizing disruption to your business and staff.",
        },
      ],
    },
  ],
  features: [
    "Deep Learning AI Models run as an application on top of your existing CCTV infrastructure",
    "Out of the box scenarios for detection include face mask detection, social distancing and configurable people density-based alerts",
    "Can add more scenarios based on your unique requirements relevant to your business operation e.g. intrusion detection, footfall analytics, Slips/Trips/Falls, Fire/Smoke detection etc",
    "Especially designed to be compliant with Personal Data Protection and Privacy Laws",
  ],
  benefits: [
    "No need to install additional cameras or sensors",
    "Makes use of your existing investments in security systems",
    "Quick operationalization as no hardware installation required",
    "Individual privacy is preserved",
    "Only aggregated alerts and triggers shared with approved company officers",
    "Prevents any abuse or misuse of the system as there is no video footage that is stored in the WorkSafe Application",
    "All data stays in your IT systems, with no access to Vulcan engineers or any 3rd Party",
  ],
  get_started_steps: [
    "Share site layout with dimensions",
    "Provide existing Camera location and access details",
    "Hardware Set Up (Cloud/On Prem options available)",
    "Establish connectivity",
    "One Time AI model Configuration",
    "Set Up Dashboard and Alerts, User Access",
  ],
}
